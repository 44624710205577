<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <!-- <ecommerce-medal :data="data.congratulations" /> -->
        <ecommerce-earnings-chart :data="earningsChart" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="statisticsItems1" />
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">

          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>

          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <ecommerce-company-table :table-data="tableData" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col> -->

      <b-col
        lg="6"
        md="6"
      >
        <ecommerce-goal-overview :data="goalOverview" />
      </b-col>

      <b-col
        lg="6"
        md="6"
      >
        <!-- <ecommerce-earnings-chart :data="earningsChart" /> -->
        <ecommerce-goal-overview :data="subscriptions" />
        <!-- <ecommerce-transactions :data="data.transactionData" /> -->
      </b-col>

    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import store from '@/store'
import { mapActions } from 'vuex'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      earningsChart: {
        title: 'Complaints Overview',
        totalPending: '',
        totalAccepted: '',
        totalRejected: '',
        series: [53, 16, 31],
      },
      goalOverview: {
        title: 'Auction Overview',
        fLable: 'Colsed',
        sLable: 'In Progress',
        tLable: 'Total Records',
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      complaint: {
        title: 'complaints Overview',
        fLable: 'Z',
        sLable: 'Pending',
        tLable: 'Total Records',
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      subscriptions: {
        fLable: 'Paid',
        sLable: 'Pending',
        tLable: 'Total Balance',
        title: 'Subscriptions Overview',
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      tableData: [
        {
          avatarImg: require('@/assets/images/icons/toolbox.svg'),
          title: 'Companies',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: 0,
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/parachute.svg'),
          title: 'Investors',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: '',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/brush.svg'),
          title: 'Freelancers',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: '',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/star.svg'),
          title: 'Brokers',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: 0,
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/book.svg'),
          title: 'Advisors',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: '',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/rocket.svg'),
          title: 'Partners',
          viewTitle: '',
          viewsub: '',
          revenue: '',
          sales: '',
          loss: false,
        },
      ],
      statisticsItems1: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Total Idea',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Total Projects',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Total Products',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Total Services',
          customClass: '',
        },
      ],
    }
  },
  computed: {
  },
  created() {
    // data
    this.$http.get('/ecommerce/data').then(response => {
      this.data = response.data

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      // const userData = getUserData()
      // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    })
  },
  mounted() {
    this.retreiveStatistics().then(response => {
      const responseData = response.data
      this.goalOverview.completed = responseData.auctionsData.totalClosed
      this.goalOverview.inProgress = responseData.auctionsData.totalCreated
      this.goalOverview.series = parseFloat(responseData.auctionsData.totalClosed / (responseData.auctionsData.totalCreated + responseData.auctionsData.totalClosed)).toFixed(1)
      this.goalOverview.series = [this.goalOverview.series]

      this.tableData[0].viewTitle = responseData.usersData.totalApprovedCompanies
      this.tableData[0].viewsub = responseData.usersData.totalUnapprovedCompanies
      this.tableData[0].revenue = responseData.usersData.totalCompanies
      this.tableData[0].sales = responseData.usersData.totalApprovedCompanies === 0 ? 0 : ((responseData.usersData.totalCompanies / responseData.usersData.totalApprovedCompanies) * 100)

      this.tableData[1].viewTitle = responseData.usersData.totalApprovedInvestors
      this.tableData[1].viewsub = responseData.usersData.totalUnapprovedInvestors
      this.tableData[1].revenue = responseData.usersData.totalInvestors
      this.tableData[1].sales = responseData.usersData.totalApprovedInvestors === 0 ? 0 : ((responseData.usersData.totalInvestors / responseData.usersData.totalApprovedInvestors) * 100)

      this.tableData[2].viewTitle = responseData.usersData.totalApprovedFreelancers
      this.tableData[2].viewsub = responseData.usersData.totalUnapprovedFreelancers
      this.tableData[2].revenue = responseData.usersData.totalFreelancers
      this.tableData[2].sales = responseData.usersData.totalApprovedFreelancers === 0 ? 0 : ((responseData.usersData.totalFreelancers / responseData.usersData.totalApprovedFreelancers) * 100)

      this.tableData[3].viewTitle = responseData.usersData.totalApprovedBrokers
      this.tableData[3].viewsub = responseData.usersData.totalUnapprovedBrokers
      this.tableData[3].revenue = responseData.usersData.totalBrokers
      this.tableData[3].sales = responseData.usersData.totalApprovedBrokers === 0 ? 0 : ((responseData.usersData.totalBrokers / responseData.usersData.totalApprovedBrokers) * 100)

      this.tableData[4].viewTitle = responseData.usersData.totalApprovedAdvisors
      this.tableData[4].viewsub = responseData.usersData.totalUnapprovedAdvisors
      this.tableData[4].revenue = responseData.usersData.totalAdvisors
      this.tableData[4].sales = responseData.usersData.totalApprovedAdvisors === 0 ? 0 : ((responseData.usersData.totalAdvisors / responseData.usersData.totalApprovedAdvisors) * 100)

      this.tableData[5].viewTitle = responseData.usersData.totalApprovedPartners
      this.tableData[5].viewsub = responseData.usersData.totalUnapprovedPartners
      this.tableData[5].revenue = responseData.usersData.totalPartners
      this.tableData[5].sales = responseData.usersData.totalApprovedPartners === 0 ? 0 : ((responseData.usersData.totalPartners / responseData.usersData.totalApprovedPartners) * 100)

      this.statisticsItems1[0].title = responseData.profileData.totalIdeas
      this.statisticsItems1[1].title = responseData.profileData.totalProjects
      this.statisticsItems1[2].title = responseData.profileData.totalProducts
      this.statisticsItems1[3].title = responseData.profileData.totalServices

      this.subscriptions.completed = responseData.subscriptions.totalPaid
      this.subscriptions.inProgress = responseData.subscriptions.totalUnpaid
      this.subscriptions.series = parseFloat((responseData.subscriptions.totalPaid / (responseData.subscriptions.totalUnpaid + responseData.subscriptions.totalPaid)) * 100).toFixed(1)
      this.subscriptions.series = [this.subscriptions.series]

      this.earningsChart.totalPending = responseData.complaintsData.totalPending
      this.earningsChart.totalAccepted = responseData.complaintsData.totalAccepted
      this.earningsChart.totalRejected = responseData.complaintsData.totalRejected
    })
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' })
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'sticky' })
  },
  methods: {
    ...mapActions({
      retreiveStatistics: 'profile/retreiveStatistics',
    }),
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
